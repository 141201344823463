@import "../helpers/include-media.scss";

.header {
    width: 100%;
     height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .content_container {
        display: flex;
        width: 70%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        .header_section{
            
            h1 {
                margin-top: 0;
                font-size: 40px;
                color: white;
                font-family: "Montserrat";
                font-weight: 500;
                text-align: center;
            }
        }
        .text_content {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: center;

            h1 {
                font-size: 40px;
                color: white;
                font-family: "Montserrat";
                font-weight: 500;
                text-align: center;
            }

            .separator_line {
                display: contents;
            }

            p {
                font-size: 18px;
                line-height: 30px;
                color: white;
                font-family: "Montserrat";
                font-weight: 300;
                text-align: center;
            }
        }
        .features_tiles {
            display: flex;
            flex-wrap: wrap;
            @include media("<tablet") {
                justify-content: center;
            }
        }

        .pricing_boxes {
            display: flex;
            flex-wrap: wrap;
        }
    }
}
