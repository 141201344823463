@import "../helpers/include-media.scss";

.app_points {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-bottom: 70px;
    background-color: #edf2f6;

    .content_container {
        display: flex;
        width: 70%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        margin: 50px 0px;

        .text_content {
            display: flex;
            flex-basis: 85%;
            flex-wrap: wrap;
            padding: 0 20px;
            h1 {
                text-align: center;
                width: 100%;
                font-size: 40px;
                line-height: 48px;
                color: #00c0e8;
                font-family: "Montserrat";
                font-weight: 500;
            }

            h3 {
                // justify-content: center;
                // text-align: center;
                flex-basis: 100%;
                margin-top: 0px;
                @include media("<tablet") {
                    margin-bottom: 0px;
                }
            }
            @include media("<tablet") {
                flex-basis: 100%;
                flex-wrap: wrap;
                justify-content: center;
                text-align: center;
            }
        }
        .column {
            display: flex;
            flex-wrap: wrap;
            flex-basis: 50%;
            position: relative;
            box-sizing: border-box;
            padding: 0 10px;

            .mobile_phone {
                position: relative;
                display: flex;
                align-items: left;
                justify-content: left;
                align-self: flex-start;
                img {
                    object-fit: cover;
                    width: 100%;
                    border-radius: 20px;
                }
            }
            .points {
                display: flex;
                flex-wrap: wrap;
                flex-basis: 50%;
                align-self: flex-start;
                align-content: flex-start;
                ul{
                    text-align: left;
                }
                li {
                    font-size: 14px;
                    line-height: 18px;
                    color: white; /* Change the color */
                    font-family: "Montserrat";
                    font-weight: 300;
                }
                li span {
                    color: white;
                }
                @include media("<tablet") {
                    flex-basis: 100%;
                    margin-top: 20px;
                }
            }

            .button {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 30px 0px;
                width: 144px;
                height: 50px;
                border-radius: 5px;
                background-image: linear-gradient(
                    90deg,
                    #863893 0%,
                    #e73b85 100%
                );

                p {
                    font-size: 18px;
                    color: #ffffff;
                    font-family: "Montserrat";
                    font-weight: 600;
                    justify-content: center;
                    text-align: center;
                    flex-basis: 100%;
                }
            }
        }
        @include media("<tablet") {
            flex-basis: 100%;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
        }

        .column.left {
            flex-basis: 40%;

            @include media("<tablet") {
                flex-basis: 100%;
            }
        }
        .column.right {
            flex-basis: 60%;
            @include media("<tablet") {
                flex-basis: 100%;
                margin-top: 20px;
            }
        }
    }
    .video_container{
        width:100%;
        video{
            width: 100%;
        }
    }
    .RHS_rectangles {
        position: absolute;
        right: 0px;
        @include media("<tablet") {
            display: none;
        }
    }
}
