@import "../helpers/include-media.scss";

.contact {
    position: relative;
    display: flex;
    width: 100%;
    background-color: #f6f9fe;
    justify-content: center;
    flex-wrap: wrap;
    .header{
        width: 100%;
        background-color: #f6f9fe;
        color: #202528;
    }
    .image_container{
        display: flex;
        flex-basis: 50%;
        img{
            object-fit: cover;
            width: 100%;
            max-height: 280px;
        }
        @include media("<tablet") {
            flex-basis: 90%;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    .content_container {
        display: flex;
        justify-content: center;
        flex-basis: 80%;
        align-items: center;
        padding-left: 1%;
        @include media("<tablet") {
            flex-basis: 90%;
            flex-wrap: wrap;
            justify-content: center;
            padding-left:0px;
        }
        .content_col {
            flex-basis: 80%;
        }
        .points ul{
            text-align: left;
            font-size:20px;
        }
        .header_row {
            width: 100%;
            h1 {
                font-size: 40px;
                color: #00c0e8;
                font-family: "Montserrat";
                font-weight: 500;
                text-align: center;
            }
        }

        .contact_section {
            display: flex;
            flex-basis: 50%;
            justify-content: center;
            @include media("<tablet") {
                flex-basis: 100%;
                flex-wrap: wrap;
                justify-content: center;
            }
        }
        .contact_form {
            justify-content: center;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            form {
                width: 100%;
            }
            h2 {
                color: white;
                @include media("<tablet") {
                    text-align: center;
                }
            }

            .row {
                position: relative;
                display: flex;
                width: 100%;
                @include media("<tablet") {
                    flex-wrap: wrap;
                }

                .col {
                    flex-basis: 100%;
                    position: relative;

                    box-sizing: border-box;

                    input {
                        box-sizing: border-box;
                        width: 100%;
                        height: 42px;
                        background-color: #ffffff;
                        border: 2px solid #cccccc;
                        font-size: large;
                        padding: 0 20px;
                        margin: 10px 0;
                        color: #999999;
                        font-family: "Proxima Nova";
                    }
                }
            }
            .submit_button {
                width: 150px;
                height: 50px;
                border-radius: 5px;
                background-image: linear-gradient(
                    90deg,
                    #863893 0%,
                    #e73b85 100%
                );
                padding: 0;
                border: none;
                margin: 20px 0;

                font-size: 20px;
                line-height: 22px;
                color: #ffffff;
                font-family: "Montserrat";
                font-weight: 400;
                text-align: center;
                cursor: pointer;
            }
        }
        .vertical_separator {
            align-self: center;
            @include media("<tablet") {
                display: none;
            }
        }
        .contact_details {
            display: flex;
            flex-basis: 50%;
            flex-wrap: wrap;
            align-content: flex-start;
            justify-content: center;

            .details {
                display: flex;
                flex-wrap: wrap;
            }
            @include media("<tablet") {
                flex-basis: 100%;
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }
}
