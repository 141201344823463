@import "../helpers/include-media.scss";

.contact_detail {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px 0;

    .content_container {
        display: flex;
        width: 100%;
        flex-basis: 100%;
        height: 48px;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;

        .list_image{
            margin-right: 20px;
        }

        p{
            margin: 0px;
           text-align: left; 
        }

    }
    .RHS_rectangles{
        position:absolute;
        right: 0px;
    }
}
